/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CPHeaderLinkWrapper,
  NavBarTypes,
  TopBarTypes,
} from '@codeparva/gatsby-theme-layout-components';
import { Link } from 'gatsby';
import { isNonMigratedFacility } from '@codeparva/gatsby-theme-tfw-1/src/utils/facilityHelpers';
import { getCurrentLocation, getFacilitySmsId } from 'utils/locationHelpers';
import { getDynamicUrl, getUrl } from '@codeparva/gatsby-theme-tfw-1/src/utils/urlUtility';

const getSocialIcons = (socialMediaLinks: any) => {
  const { facebook_url, instagaram_url, linkedin_url, twitter_url } = socialMediaLinks;
  const newSocialIcons: TopBarTypes.TIconsType[] = [];
  if (facebook_url) {
    newSocialIcons.push({
      iconName: 'FaFacebook',
      iconsUrl: facebook_url,
    });
  }
  if (twitter_url) {
    newSocialIcons.push({
      iconName: 'FaTwitter',
      iconsUrl: twitter_url,
    });
  }
  if (instagaram_url) {
    newSocialIcons.push({
      iconName: 'FaInstagram',
      iconsUrl: instagaram_url,
    });
  }
  if (linkedin_url) {
    newSocialIcons.push({
      iconName: 'FaLinkedin',
      iconsUrl: linkedin_url,
    });
  }
  return newSocialIcons;
};

const transformLocationDetailsTopBar = (locationDetails: any) => {
  if (
    locationDetails &&
    locationDetails.attributes &&
    locationDetails.attributes.location_contact_details
  ) {
    const {
      attributes: {
        location_contact_details: {
          location_social_media_links,
          location_email_address,
          location_facility_address,
          location_google_maps_url,
          location_phone_number,
          location_mailing_address,
        },
        location_name,
        // location_state,
        // location_city,
      },
    } = locationDetails;

    return {
      id: locationDetails.id,
      // Adding if check for social media icons
      ...(location_social_media_links && {
        socialIcons: getSocialIcons(location_social_media_links),
      }),
      contactDetails: {
        // mailingAddress: location_mailing_address || location_facility_address,
        emailID: location_email_address,
        phoneNumber: location_phone_number,
        facilityDetails: {
          facilityAddress: '',
          googleMapsUrl: location_google_maps_url,
          facilityName: location_name,
        },
      },
    };
  }
};

const transformLocationDetailsNavBar = (locationDetails: any, facilityDetails: any) => {
  if (locationDetails.attributes.location_contact_details) {
    const {
      attributes: {
        location_contact_details: {
          location_social_media_links,
          location_facility_address,
          location_google_maps_url,
          location_phone_number,
        },
        location_name,
        // location_state,
        // location_city,
      },
    } = locationDetails;
    const { facility_logo } = facilityDetails;
    const facilityLogoUrl = facility_logo?.data?.attributes?.url || '';
    const facilityLogoAltText = facility_logo?.data?.attributes?.alternativeText || '';
    const updatedLocation: NavBarTypes.TLocation = {
      id: locationDetails.id,
      ...(location_social_media_links && {
        socialIcons: getSocialIcons(location_social_media_links),
      }),
      phoneNumber: location_phone_number,
      facilityDetails: {
        facilityAddress: location_facility_address,
        googleMapsUrl: location_google_maps_url,
        facilityName: location_name,
        facilityLogo: {
          logo: facilityLogoUrl,
          altText: facilityLogoAltText,
          renderAs: 'figure',
        },
      },
    };
    return updatedLocation;
  }
};

export const transformTopBarConfig = (
  topbarData: TopBarTypes.ITopBarData,
  strapiTopBarConfig: any,
  locationDetails: any,
) => {
  const getAnnouncementBarConfig = () => {
    const { announcement_bar_config } = strapiTopBarConfig;
    return announcement_bar_config?.show_announcement || false;
  };

  if (strapiTopBarConfig) {
    const { buttons, section_variant, announcement_bar_config } = strapiTopBarConfig;
    topbarData.hideOnMobileDevice = strapiTopBarConfig.hide_on_mobile_device;
    topbarData.showAnnouncementBar = false;
    topbarData.announcementInfo = '';
    if (strapiTopBarConfig.announcement_bar_config) {
      topbarData.showAnnouncementBar = getAnnouncementBarConfig();
      topbarData.announcementInfo = announcement_bar_config.announcement_info || '';
    }
    topbarData.closeButton = 'FaWindowClose';
    topbarData.ctaText = strapiTopBarConfig.call_to_action_text;
    topbarData.syrasoftEmailID = strapiTopBarConfig.syrasoft_email_id || '';
    topbarData.variant = section_variant.replace(/_/g, '-');
    topbarData.theme = strapiTopBarConfig.theme.section_theme ?? 'dark';
    // If check for buttons
    if (buttons && buttons[0]) {
      const { show_button, label, show_button_on_touch_device } = buttons[0];
      topbarData.buttonsConfig = {
        text: label,
        iconName: 'FaDollarSign',
        show: show_button,
        showOnTouchDevice:
          show_button_on_touch_device ?? topbarData.buttonsConfig.showOnTouchDevice,
        url: getUrl(locationDetails, 'payment'),
      };
    }
    if (locationDetails && locationDetails.length > 0) {
      topbarData.locationType = locationDetails.length > 1 ? 'multi' : 'single';
      topbarData.locations = {
        //   Revisit for current location
        location: transformLocationDetailsTopBar(locationDetails[1]),
      };
    }
  }
  return topbarData;
};
export const getSubLinkUrl = (locationDetails: any, subLinkUrl: string) => {
  return locationDetails.map(
    ({ id, attributes: { location_name, location_state, location_city } }: any) => {
      return {
        subLinkName: location_name,
        subLinkUrl,
        location_state: location_state.toLowerCase(),
        location_city: location_city.toLowerCase(),
        location_id: id,
      };
    },
  );
};
export const generateNavLinks = (
  locationDetails: any,
  facilityDetails: any,
  componentType: string,
  isPaymentEnabled: boolean,
) => {
  const newNavLinks: NavBarTypes.TNavLink[] = [];
  const { location_city = '', location_state = '' } = locationDetails[0]?.attributes ?? {};
  let url = '';
  if (location_city && location_state) {
    url = `/${location_state.toLowerCase()}/${location_city.toLowerCase()}`;
  }
  const LinkWrapper = componentType === 'header' ? CPHeaderLinkWrapper : Link;
  newNavLinks.push({
    NavLinkText: 'Home',
    isNavLinkDropDown: false,
    isGrouped: false,
    linkTo: '/',
    RenderAs: LinkWrapper,
    newTab: true,
    navLinkDropDown: [],
  });

  if (locationDetails?.length) {
    if (url) {
      newNavLinks.push({
        NavLinkText: locationDetails.length > 1 ? 'Locations' : 'View Rates',
        isNavLinkDropDown: locationDetails.length > 1 ? true : false, // in case of single location this is false else it is true
        isGrouped: false, // for grouping of dropdowns
        linkTo: componentType === 'footer' ? `${url}/view-rates` : '/view-rates',
        RenderAs: LinkWrapper,
        newTab: true,
        navLinkDropDown:
          locationDetails.length > 1 ? getSubLinkUrl(locationDetails, 'view-rates') : [],
      });
    }

    if (
      componentType !== 'footer' &&
      !isNonMigratedFacility(facilityDetails.facility_sms_id) &&
      isPaymentEnabled
    ) {
      newNavLinks.push({
        NavLinkText: 'Pay Rent',
        isNavLinkDropDown: false,
        isGrouped: false,
        linkTo: componentType === 'footer' ? `${url}/account/login` : '/account/login',
        RenderAs: LinkWrapper,
        newTab: true,
        navLinkDropDown: [],
      });
    }
  }

  const { static_pages_to_enable } = facilityDetails;

  if (static_pages_to_enable) {
    if (static_pages_to_enable.storage_tips) {
      newNavLinks.push({
        NavLinkText: 'Storage Tips',
        isNavLinkDropDown: false,
        isGrouped: false,
        linkTo: '/storage-tips',
        RenderAs: LinkWrapper,
        newTab: true,
        navLinkDropDown: [],
      });
    }

    if (static_pages_to_enable.frequently_asked_questions) {
      newNavLinks.push({
        NavLinkText: 'FAQ',
        isNavLinkDropDown: false,
        isGrouped: false,
        linkTo: '/faq',
        RenderAs: LinkWrapper,
        newTab: true,
        navLinkDropDown: [],
      });
    }

    if (static_pages_to_enable.storage_calculator) {
      newNavLinks.push({
        NavLinkText: 'Storage Calculator',
        isNavLinkDropDown: false,
        isGrouped: false,
        linkTo: '/storage-calculator',
        RenderAs: LinkWrapper,
        newTab: true,
        navLinkDropDown: [],
      });
    }
  }
  newNavLinks.push({
    NavLinkText: 'Contact Us',
    isNavLinkDropDown: false,
    linkTo: componentType === 'footer' ? `${url}/contact` : '/contact', // in case the locationtype is multi the url has to be changed to '/select-location-page in case single of single /cityname/statename/contact
    RenderAs: LinkWrapper,
    newTab: true,
    navLinkDropDown: [],
  });
  return newNavLinks;
};

export const getTransformedButtonConfig = (
  buttons: any,
  locationDetails: any,
  location_city: string,
  location_state: string,
  isPaymentEnabled: boolean,
  smsFacilityId: string,
) => {
  const res: any[] = [];
  buttons.forEach((ctaButton: any) => {
    const {
      url,
      label,
      cta_icon_config,
      cta_button_theme: { section_theme = {} } = {},
      show_button,
    } = ctaButton;

    const hideButton =
      (!isPaymentEnabled && (url.includes('reserve') || url.includes('pay_rent'))) ||
      isNonMigratedFacility(smsFacilityId);
    if (show_button && !hideButton) {
      const updatedUrl = getDynamicUrl(url, location_state, location_city);
      const button: any = {
        label,
        url: getUrl(locationDetails, updatedUrl),
        sectionTheme: section_theme,
      };
      if (cta_icon_config) {
        button.icon = {
          name: cta_icon_config.icon_name,
          position: cta_icon_config.icon_position,
          type: cta_icon_config.icon_type,
          size: 16,
        };
      }
      res.push(button);
    }
  });
  return res;
};

export const transformNavBarConfig = (
  navBarData: NavBarTypes.INavBarConfig,
  strapiNavBarConfig: any,
  locationDetails: any,
  facilityDetails: any,
  loggedInSmsFacilityId?: string,
) => {
  const smsFacilityId = getFacilitySmsId(locationDetails, loggedInSmsFacilityId, facilityDetails);
  const isPaymentEnabled = facilityDetails.isPaymentEnabled;
  if (strapiNavBarConfig) {
    const { buttons } = strapiNavBarConfig;
    buttons.map((button: any, index: number) => {
      button.cta_button_theme = {};
      if (button.cta_button_theme) {
        index === 0
          ? (button.cta_button_theme.section_theme = 'light')
          : (button.cta_button_theme.section_theme = 'dark');
      }
    });
    if (locationDetails && locationDetails.length > 0) {
      navBarData.locationType = locationDetails.length > 1 ? 'multi' : 'single';
      navBarData.locations = {
        location: transformLocationDetailsNavBar(locationDetails[0], facilityDetails),
      };
    }
    const { attributes: { location_state = '', location_city = '' } = {} } =
      getCurrentLocation(locationDetails, smsFacilityId) ?? {};
    navBarData.buttonsConfig = getTransformedButtonConfig(
      buttons,
      locationDetails,
      location_city,
      location_state,
      isPaymentEnabled,
      smsFacilityId,
    );

    navBarData.navLinks = generateNavLinks(
      locationDetails,
      facilityDetails,
      'header',
      isPaymentEnabled,
    );
    navBarData.LinkWrapper = Link;
    navBarData.theme = 'dark';
  }
  return navBarData;
};
