import { Column } from '@codeparva/ui-components-library';
import React from 'react';
import { TopBarTypes } from '@codeparva/gatsby-theme-layout-components/src/sections/sitewide/components/TopBar/topBarTypes';
import { FontAwesomeIcon } from '@codeparva/gatsby-theme-layout-components/src/utilities/iconLoader/FontAwesomeIcon';
import { HEADER_ICON_SIZES } from '@codeparva/gatsby-theme-layout-components/src/sections/sitewide/components/Header/constants';

export const themeSelector = (variantType: TopBarTypes.TVariantModes) => {
  switch (variantType) {
    case 'topbar-2':
      return 'light';

    case 'topbar-3':
      return 'dark';

    default:
      variantType = 'topbar-1';
      return 'light';
  }
};
export const GenericFacilityDetails = ({
  variant,
  facilityDetailsType,
  data,
  iconsList,
  redirectDetails,
  contactDetails,
}: TopBarTypes.TGenericFacilityDetails) => {
  switch (facilityDetailsType) {
    case 'address':
      return (
        <FacilityDetailsHelperAddress
          iconsList={iconsList}
          data={data}
          contactDetails={contactDetails}
          variantType={variant}
        />
      );
    case 'email':
      return (
        <FacilityDetailsHelperEmail
          iconsList={iconsList}
          email={data}
          contactDetails={contactDetails}
          redirectDetails={redirectDetails}
          variantType={variant}
        />
      );
    case 'mailingAddress':
      return (
        <FacilityDetailsHelperMailingAdress
          iconsList={iconsList}
          contactDetails={contactDetails}
          mailAddress={data}
          variantType={variant}
        />
      );
    case 'phone':
      return (
        <FacilityDetailsHelperPhone
          iconsList={iconsList}
          contactDetails={contactDetails}
          redirectDetails={redirectDetails}
          phone={data}
          variantType={variant}
        />
      );
    default:
      return <></>;
  }
};
export const TopBarFacilityDetailsHelper = (
  contactDetails: TopBarTypes.TContactDetails
) => {
  let phoneNumberRedirect: string = '';
  if (contactDetails.phoneNumber) {
    phoneNumberRedirect = `tel:${contactDetails.phoneNumber.replace(
      /[^a-zA-Z0-9]/g,
      ''
    )}`;
  }

  return phoneNumberRedirect;
};

export const FacilityDetailsHelperAddress = ({
  iconsList,
  data,
  variantType,
}: TopBarTypes.TFacilityDetailsAddress) => {
  const selectedTheme = themeSelector(variantType);
  const textColor = selectedTheme === 'light' ? 'primary' : 'white';

  const getClassNameForAnchorAddress = () => {
    return `facilityDetails ${variantType === 'topbar-4' && 'pr-2'} ${variantType === 'topbar-2' && 'icons'
      } has-text-${textColor} has-text-weight-bold`;
  };
  return (
    data?.facilityAddress?   <>
      <FontAwesomeIcon
        iconClassName={iconsList.AddressIcon}
        iconContextProps={{
          className: `has-text-${textColor} icons`,
          style: {
            fontSize: HEADER_ICON_SIZES.facilityDetailsiconSize,
          },
        }}
      />
      &nbsp;
      <a
        href={data?.googleMapsUrl}
        rel="noreferrer"
        target="_blank"
        className={getClassNameForAnchorAddress()}
      >
        {data?.facilityAddress}
      </a>
    </> : <></>
  );
};

export const FacilityDetailsHelperEmail = ({
  iconsList,
  email,
  redirectDetails,
  variantType,
}: TopBarTypes.TFacilityDetailsEmail) => {
  const selectedTheme = themeSelector(variantType);
  const textColor = selectedTheme === 'light' ? 'primary' : 'white';

  const getIconContextProps = () => {
    return {
      className: `has-text-${textColor} icons ${variantType === 'topbar-1' || ('topbar-3' && 'socialicons-emailid')
        } ${variantType === 'topbar-2' && 'emailAddressTwo'}`,
      style: {
        fontSize: HEADER_ICON_SIZES.facilityDetailsiconSize,
      },
    };
  };

  const getClassNameForEmailAnchorTag = () => {
    return `facilityDetails ${variantType === 'topbar-4' && 'pr-2'} ${variantType === 'topbar-1' || ('topbar-3' && 'socialicons-emailid')
      } ${variantType === 'topbar-2' && 'emailAddressTwo icons'
      } has-text-${textColor} has-text-weight-bold`;
  };

  return (
    <>
      {
        email !== null ?
          <>
            <FontAwesomeIcon
              iconClassName={iconsList.EmailIcon}
              iconContextProps={getIconContextProps()}
            />
            &nbsp;
            <a
              href={redirectDetails?.emailIDRedirect}
              rel="noreferrer"
              className={getClassNameForEmailAnchorTag()}
            >
              {email}
            </a>
          </>
          : <></>
      }
    </>
  );
};

export const FacilityDetailsHelperPhone = ({
  iconsList,
  redirectDetails,
  phone,
  variantType,
}: TopBarTypes.TFacilityDetailsPhone) => {
  const selectedTheme = themeSelector(variantType);
  const textColor = selectedTheme === 'light' ? 'primary' : 'white';

  const getClassNameForPhoneNo = () => {
    return `facilityDetails ${variantType === 'topbar-4' && 'pr-2'}${variantType === 'topbar-1' && 'socialicons-phonenumber'
      } ${variantType === 'topbar-4' && 'phoneIconVariant4'} 
    ${variantType === 'topbar-3' && 'pr-4'} has-text-${textColor} has-text-weight-bold`;
  };

  const getIconPropsForPhone = () => {
    return {
      className: `has-text-${textColor} icons ${variantType === 'topbar-1' && 'socialicons-phonenumber'
        }`,
      style: {
        fontSize: HEADER_ICON_SIZES.facilityDetailsiconSize,
      },
    };
  };

  return (
    <>
      <FontAwesomeIcon
        iconClassName={iconsList.PhoneNumberIcon}
        iconContextProps={getIconPropsForPhone()}
      />
      &nbsp;
      <a
        href={redirectDetails?.phoneNumberRedirect}
        rel="noreferrer"
        className={getClassNameForPhoneNo()}
      >
        {phone}
      </a>
    </>
  );
};

export const FacilityDetailsHelperMailingAdress = ({
  iconsList,
  mailAddress,
  variantType,
}: TopBarTypes.TFacilityDetailsMailingAddress) => {
  const selectedTheme = themeSelector(variantType);
  const textColor = selectedTheme === 'light' ? 'primary' : 'white';

  const getIconPropsforMailingAddress = () => {
    return {
      className: `has-text-${textColor} icons mailingAddress ${variantType === 'topbar-4' && 'icons'
        }`,
      style: {
        fontSize: HEADER_ICON_SIZES.facilityDetailsiconSize,
      },
    };
  };

  return (
    <>
      <FontAwesomeIcon
        iconClassName={iconsList.MailingAddressIcon}
        iconContextProps={getIconPropsforMailingAddress()}
      />
      &nbsp;
      <a
        className={`facilityDetails icons mailingAddress has-text-${textColor} has-text-weight-bold`}
        rel="noreferrer"
      >
        {mailAddress}
      </a>
    </>
  );
};

export const CommonFacilityDetailsHelper = ({
  contactDetailsInfo,
  contactDetails,
  iconsList,
  redirectDetails,
  contactDetailsType,
  variantType,
}: TopBarTypes.TCommonFacilityDetails) => {
  return (
    <Column className="is-desktop is-flex is-justify-content-center is-align-items-center">
      <>
        <GenericFacilityDetails
          variant={variantType}
          facilityDetailsType={contactDetailsType}
          data={contactDetailsInfo}
          iconsList={iconsList}
          redirectDetails={redirectDetails}
          contactDetails={contactDetails}
        />
      </>
    </Column>
  );
};
