import { Column, Columns } from '@codeparva/ui-components-library';
import React from 'react';
import { ClickableText } from '@codeparva/gatsby-theme-layout-components/src/components/clickableText/ClickableText';
import { FontAwesomeIcon } from '@codeparva/gatsby-theme-layout-components/src/utilities/iconLoader/FontAwesomeIcon';
import { FooterTypes } from '@codeparva/gatsby-theme-layout-components/src/sections/sitewide/components/Footer/footerTypes';

export const NewFooterReachUs = ({
  address,
  googleMapUrl,
  phone,
  emailAddress,
}: FooterTypes.INewFooterReachUs) => {
  const ReachUsIcon = ({ iconClassName, label }: FooterTypes.TReachUsIcon) => {
    return (
      <div className="subtitle1 pb-3 is-flex is-align-items-center">
        <FontAwesomeIcon
          iconClassName={iconClassName}
          iconContextProps={{
            className: `has-text-white footerIcon mr-3`,
            style: {
              fontSize: 24,
            },
          }}
        />
        {label}
      </div>
    );
  };

  return (
    <>
     {address && <Columns>
        <Column className="pl-0 pb-0">
          <ReachUsIcon iconClassName="FaMapMarkerAlt" label="Location" />
          <ClickableText text={address} type="address" url={googleMapUrl} />
        </Column>
      </Columns>}
      <Columns>
        <Column className="pl-0 pb-0">
          <ReachUsIcon iconClassName="FaPhoneAlt" label="Call" />
          <ClickableText text={phone} type="phone" />
        </Column>
      </Columns>
      <Columns>
        <Column className="pl-0 pb-0">
          <ReachUsIcon iconClassName="FaEnvelope" label="E-Mail" />
          <ClickableText text={emailAddress} type="email" />
        </Column>
      </Columns>
    </>
  );
};
