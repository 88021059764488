import * as React from 'react';
import { Header as HeaderSection } from '@codeparva/gatsby-theme-layout-components';
import { navbardata, topbardata } from '@codeparva/gatsby-theme-tfw-1/src/components/Header/config';
import { THeaderTypes } from '@codeparva/gatsby-theme-tfw-1/src/components/Header/HeaderTypes';
import {
  transformNavBarConfig,
  transformTopBarConfig,
} from '@codeparva/gatsby-theme-tfw-1/src/components/Header/transformer';
import { FacilityContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/facilityContext';
import { LocationsContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/locationsContext';
import { LayoutContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/layoutContext';
import '../../override.scss';

export const TopBar = ({ onSetHeaderHeight }: THeaderTypes) => {
  const facilityDetails = React.useContext(FacilityContext);
  const locationDetails: any = React.useContext(LocationsContext);
  const layoutDetails = React.useContext(LayoutContext);
  const { topbarConfig, navbarConfig } = layoutDetails;

  const transformedTopbarData = transformTopBarConfig(topbardata, topbarConfig, locationDetails);
  const transformedNavbarData = transformNavBarConfig(
    navbardata,
    navbarConfig,
    locationDetails,
    facilityDetails,
  );
  if (locationDetails && locationDetails.length > 0) {
    const requiredLocationDetails = locationDetails.map(
      ({ attributes: { location_name, location_state, location_city, facility_sms_id } }: any) => ({
        location_name,
        location_state: location_state?.toLowerCase() || '',
        location_city: location_city?.toLowerCase() || '',
        facility_sms_id,
      }),
    );
    return (
      <HeaderSection
        topbardata={topbarConfig ? transformedTopbarData : {}}
        navbardata={transformedNavbarData}
        onSetHeaderHeight={onSetHeaderHeight}
        locationDetails={requiredLocationDetails}
      />
    );
  }
  return (
    <div data-testid="layout-header">
      <HeaderSection
        topbardata={topbarConfig ? transformedTopbarData : {}}
        navbardata={transformedNavbarData}
        onSetHeaderHeight={onSetHeaderHeight}
      />
    </div>
  );
};
