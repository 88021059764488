import React from 'react';
import { Button, Column, Columns } from '@codeparva/ui-components-library';
import { HeroTypes } from '../HeroTypes'
import { Row } from '@codeparva/gatsby-theme-layout-components/src/components/row/Row';
import { useThemeContext } from '@codeparva/gatsby-theme-layout-components/src/sections/locationPage/components/hero/hooks/themeContext';
import { useHeroVariant } from '@codeparva/gatsby-theme-layout-components/src/sections/locationPage/components/hero/hooks/variantContext';
import { FontAwesomeIcon } from '@codeparva/gatsby-theme-layout-components/src/utilities/iconLoader/FontAwesomeIcon';
import { useJumbotronContext } from '@codeparva/gatsby-theme-layout-components/src/sections/homePage/components/jumbotron/context/jumbotronContext';

export const SectionHeader = ({
  title,
  subtitle = '',
  cta,
  setShowModalMapLocation,
  locationMapConfig,
  showModalClickHandler,
}: HeroTypes.ISectionHeader) => {
  const curTheme = useThemeContext();
  const curVariant = useHeroVariant();
  const locationData = locationMapConfig;

  const jumbotronContext = useJumbotronContext();
  const { jumbotron_subtitle: { description = '' } } = jumbotronContext;
  const generateClassNames = (): HeroTypes.TSectionHeaderClassNames => {
    if (curTheme === 'light') {
      return {
        bgClass: 'has-background-secondary',
        titleClass: 'has-text-text-color-primary sectionTitle',
        ctaProps: {
          buttonColor: 'primary',
          theme: 'light',
        },
      };
    } else {
      return {
        bgClass: 'has-background-primary',
        titleClass: 'has-text-white sectionTitle',
        ctaProps: {
          buttonColor: 'secondary',
          theme: 'dark',
        },
      };
    }
  };

  const classNames = generateClassNames();

  const showCTAButton = () => {
    if (cta) {
      // const { ctaProps } = classNames;
      return (
        <Column
          className="ctaCol"
          mobile={{ size: curVariant !== 'hero-section-1' ? 4 : 12 }}
          tablet={{ size: 2 }}
        >
          {/* <CallToActionButton {...cta} {...ctaProps} /> */}
          <a
            href={locationData.locations[0].locationUrl}
            className={`is-flex is-align-items-center-desktop ${'has-text-secondary'}`}
            target="_blank"
          >
            <Button
              onClick={showModalClickHandler}
              className={`has-background-${curTheme === 'light' ? 'primary' : 'secondary'
                }
              has-text-${curTheme === 'light' ? 'secondary' : 'primary'}
              `}
            >
              View Map
            </Button>
          </a>
        </Column>
      );
    }
    return <></>;
  };

  const Variant1 = () => {
    return (
      <>
        <Column className="sectionTitleCol" tablet={{ size: cta ? 10 : 12 }}>
          <h3 className={`mb-0 pt-2 pl-2 ${classNames.titleClass}`}>
            {title}
          </h3>
          <p>{description}</p>
        </Column>
        {showCTAButton()}
      </>
    );
  };

  const Variant2 = () => {
    return (
      <>
        <Column
          className="sectionTitleCol is-mobile"
          tablet={{
            size: 10,
          }}
          mobile={{
            size: cta ? 8 : 12,
          }}
          justifyContent="center"
        >
          <p
            className={`${classNames.titleClass} has-text-weight-bold py-4`}
          >
            {title}
          </p>
          <span className='content1'>{description}</span>
        </Column>
        {showCTAButton()}
      </>
    );
  };
  const Variant3 = () => {
    return (
      <>
        {title && (
          <div className="pageContentTitle is-flex is-align-items-center is-flex-direction-column">
            <h1>{title}</h1>
            {subtitle && <h3>{subtitle}</h3>}
            <h5 className="pt-4">{description}</h5>
            <div className="py-4">
              <div>
                <a
                  href={locationData.locations[0].locationUrl}
                  className={`is-flex is-align-items-center ${'has-text-secondary'} subtitle1`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    iconClassName={'FaMapMarker'}
                    iconContextProps={{
                      className: `${'has-text-secondary'} pr-3`,
                      style: {
                        fontSize: 28,
                      },
                    }}
                  />
                  {locationData.locations[0].addressText}
                </a>
              </div>
            </div>
            <Button
              className="cp-primary-button-highlighted buttonColor"
              onClick={() => {
                setShowModalMapLocation && setShowModalMapLocation();
              }}
            >
              View Map
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderVariant = {
    'hero-section-1': Variant1,
    'hero-section-2': Variant2,
    'hero-section-3': Variant3,
    'hero-section-4': Variant1,
  };
  const VariantToRender = renderVariant[curVariant];

  if (curVariant === 'hero-section-3') {
    return <Variant3 />;
  } else {
    return (
      <Row
        className={`
      is-cp-w-100
      my-4
      ${classNames.bgClass}
    `}
      >
        <Columns
          className={`is-cp-w-100 ${curVariant !== 'hero-section-1' ? 'is-mobile' : ''
            }`}
          multiline={false}
        >
          <VariantToRender />
        </Columns>
      </Row>
    );
  }
};
