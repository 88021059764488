import { HTMLAttributes, ReactChild } from 'react';
import { ClickableTextTypes } from '@codeparva/gatsby-theme-layout-components/src/components/clickableText/ClickableTextTypes';
import { CTAButtonTypes } from '@codeparva/gatsby-theme-layout-components/src/components/ctaButton/CTAButtonTypes';
import { TLazyIconKey } from '@codeparva/gatsby-theme-layout-components/src/utilities/iconLoader/FontAwesomeIcon';
import {
  AboutUsTypes,
  WhatWeOfferTypes,
  WhyChooseUsTypes,
} from '@codeparva/gatsby-theme-layout-components/src/sections/homePage';
import { LocationTypes } from '@codeparva/gatsby-theme-layout-components/src/sections/sitewide';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace HeroTypes {
  export interface ILocationHero extends HTMLAttributes<HTMLDivElement> {
    locationDetails: LocationTypes.ILocationMap;
    sectionHeader: ISectionHeader;
    facilityAmenities?: TAmenities;
    sectionFooter?: {
      ctaButtons: ICustomCTAButtons[];
    };
    photos?: TLocationPhoto[];
    variant?: TLocationHeroVariant;
    theme?: TTheme;
    setShowModalMapNotification?: () => void;
    media?: THeroSectionImage;
    headHeight?: never;
  }

  export interface THeroSectionImage {
    url: string;
    alternativeText: string;
    caption: string;
    file: IFile;
  }
  export interface IFile {
    childImageSharp: IChildImageSharp;
  }
  export interface IChildImageSharp {
    gatsbyImageData: IGatsbyImageData;
  }
  export interface IGatsbyImageData {
    layout: string;
    backgroundColor: string;
    images: TImages;
    width: number;
    height: number;
  }
  export type TImages = {
    fallback: TFallback;
    sources?: null[] | null;
  };
  export type TFallback = {
    src: string;
    srcSet: string;
    sizes: string;
  };

  export interface IGallery {
    photos?: TLocationPhoto[];
  }

  export interface IFacilityDetails {
    location: LocationTypes.ILocationMap;
    facilityAmenities?: TAmenities;
  }
  export interface ISectionHeader extends HTMLAttributes<HTMLDivElement> {
    title: string;
    subtitle?: string;
    cta?: CTAButtonTypes.TCallToAction;
    setShowModalMapLocation?: () => void;
    locationMapConfig: LocationTypes.ILocationMap;
    showModalClickHandler?: () => void;
  }

  export type TLocationPhoto = {
    url: string;
    caption?: string;
    gatsbyImageData?: AboutUsTypes.TGatsbyImageData;
  };

  export type TRenderDetails = {
    label: string;
    content: ReactChild;
    noPadding?: boolean;
    clickableTextConfig?: ClickableTextTypes.IClickableText;
  };

  export type TLocationHeroVariant =
    | 'hero-section-1'
    | 'hero-section-2'
    | 'hero-section-3'
    | 'hero-section-4';

  export interface ICustomCTAButtons extends CTAButtonTypes.TCallToAction {
    customCTAtype: 'call' | 'directions' | 'payRent';
  }

  export type TTheme = 'light' | 'dark';
  export type TSectionHeaderClassNames = {
    bgClass: string;
    titleClass: string;
    ctaProps: Pick<CTAButtonTypes.TCallToAction, 'buttonColor' | 'theme'>;
  };

  export type TAmenities = {
    variant: 'Why Choose Us' | 'What We Offer';
    whyChooseUsConfig?: WhyChooseUsTypes.IWhyChooseUs;
    whatWeOfferConfig?: WhatWeOfferTypes.IWhatWeOffer;
  };

  export type TFacilityDetailsVariant = {
    curLocation: LocationTypes.TLocation;
    addressDetails: ClickableTextTypes.IClickableText;
    renderDetails: ({
      label,
      content,
      clickableTextConfig,
    }: HeroTypes.TRenderDetails) => JSX.Element;
    genericIcon: ({ iconName }: CTAButtonTypes.TGenericIconType) => JSX.Element;
    formattedHrs: {
      officeHrs: string[];
      gateHrs: string[];
    };
    GenericTextComponent: (
      props: HeroTypes.TGenericTextComponent
    ) => JSX.Element;
    facilityAmenities?: TAmenities;
  };

  export type TGenericTextComponent = {
    text: string;
    icon: TLazyIconKey;
    type: ClickableTextTypes.TLinkType;
  };
}
